import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { Container, Row, Col  } from 'react-bootstrap'

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Container>
      <h1 className="text-center mt-4 mt-5">Dr. Priscilla Thomas Annex</h1>
      <h2 className="text-center">The perfect multipurpose events space for your business and social events.</h2>
      <Container className="singleCol text-center">
      <div className="d-md-flex justify-content-evenly">
        <div>
          <h3>Hours</h3>
          <p>Hourly and all day rental.</p>
        </div>
        <div>
          <h3>Location</h3>
          <p>460 Martin Luther King, Jr. Blvd. <br />
          Savannah, Georgia 31401</p>
        </div>
        <div>
          <h3>Contact</h3>
          <p>Diane Green <br />912-349-3103</p>
        </div>
      </div>
      </Container>
      <Row>
        <Col lg={4}>
          <p>
            <StaticImage
              src="../images/inside-of-annex.jpg"
              quality={75}
              formats={["auto", "webp", "avif"]}
              alt="Annex Interior"
              aspectRatio={1}
            />
          </p>
        </Col>
        <Col lg={4}>
          <p>
            <StaticImage
              src="../images/index-of-annex-2.jpg"
              quality={75}
              formats={["auto", "webp", "avif"]}
              alt="Annex Interior"
              aspectRatio={1}
            />
          </p>
        </Col>
        <Col lg={4}>
          <p>
            <StaticImage
              src="../images/annex-outside-square.jpg"
              quality={75}
              formats={["auto", "webp", "avif"]}
              alt="Outside of the Annex"
              aspectRatio={1}
            />
          </p>
        </Col>
      </Row>
      <div className="d-flex justify-content-center">
        <div>
        <h2 className="text-center">About</h2>
        <ul>
          <li>Seating capacity 380</li>
          <li>Banquet style chairs and tables</li>
          <li>Prep kitchen</li>
          <li>Event coordinator on staff</li>
          <li>Call for details and pricing</li>
        </ul>
        </div>
      </div>
      </Container>
      
  </Layout>
)

export const Head = () => <Seo title="Dr. Priscilla Thomas Annex" />

export default SecondPage
